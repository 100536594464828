import { Box, ChakraProvider, extendTheme } from '@chakra-ui/react'
import Theme from 'components/theme/global-style'

import React, { lazy, Suspense } from 'react'
import { LineWave } from 'react-loader-spinner'
import { ThemeProvider } from 'styled-components'
import { useUser } from '../features/auth/store'
import './style.css'
import { Container, SpinDiv } from './styles'
import { ErrorBoundary } from './ErrorBoundary'

const Authenticated = lazy(() => import('./Authenticated'))
const Unauthenticated = lazy(() => import('./Unauthenticated'))

// 2. Extend the theme to include custom colors, fonts, etc
const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1048px',
  '2xl': '1536px',
}
const theme = extendTheme({ breakpoints })

const Loading = () => (
  <Box
    minHeight="100svh"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <SpinDiv>
      <LineWave color="#006CAE" />
    </SpinDiv>
  </Box>
)

const App = () => {
  const { isLoggedIn, user, loading } = useUser()

  const roles = user?.roles || []

  if (loading) {
    return <Loading />
  }

  return (
    <Container>
      <Suspense fallback={<Loading />}>
        {isLoggedIn ? <Authenticated roles={roles} /> : <Unauthenticated />}
      </Suspense>
    </Container>
  )
}

export default () => (
  <ThemeProvider theme={theme}>
    <Theme />
    <ChakraProvider resetCSS>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </ChakraProvider>
  </ThemeProvider>
)
