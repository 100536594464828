import styled from 'styled-components'
export const SpinDiv = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  ${'' /* margin-top: 300px; */}
`
export const Container = styled.div`
  * {
    outline: none;
  }
`
