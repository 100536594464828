import { adapter } from 'config'
import { logger } from 'helpers/logger'
import { useCallback, useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { create } from 'zustand'

const userAtom = create((set) => ({
  user: null,
  isLoggedIn: false,
  loading: true,
  dispatchUser: ({ type, payload }) => {
    switch (type) {
      case 'SAVE_USER':
        set({ user: payload, isLoggedIn: true, loading: false })
        break
      case 'LOGOUT':
        set({ user: null, isLoggedIn: false, loading: false })
        break
      default:
        logger.error('Invalid action type')
        break
    }
  },
}))

export const useUser = () => {
  const { user, isLoggedIn, dispatchUser: dispatch, loading } = userAtom()

  const fetchUser = useCallback(async () => {
    try {
      const { data, status } = await adapter.get('/me?$jwt=true')

      if (status === 200) {
        dispatch({ type: 'SAVE_USER', payload: data })
      } else {
        dispatch({ type: 'LOGOUT' })
      }
    } catch (err) {
      //
      dispatch({ type: 'LOGOUT' })
    }
  }, [dispatch])

  useEffect(() => {
    if (!isLoggedIn) {
      fetchUser()
    }
  }, [isLoggedIn, fetchUser])

  useLocalStorage('user-token', user?.token?.toString())

  return {
    user,
    isLoggedIn,
    dispatchUser: dispatch,
    userData: userAtom.getState(),
    loading,
  }
}
