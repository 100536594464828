export const logger = {
  info: (...args) => {
    console.log('WEB: INFO ', ...args)
  },
  warn: (...args) => {
    console.log('WEB: WARN ', ...args)
  },
  error: (...args) => {
    console.log('WEB: ERROR ', ...args)
  },
  success: (...args) => {
    console.log('WEB: DEBUG ', ...args)
  },
}
