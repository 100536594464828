import { Box, Button, Text } from '@chakra-ui/react'
import React from 'react'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          minHeight="100svh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="1rem"
        >
          <Text fontFamily="mono">
            Something went wrong. Please reload the page.
          </Text>
          <Button
            onClick={() => {
              this.setState({ hasError: false })
            }}
          >
            Reload
          </Button>
        </Box>
      )
    }

    return this.props.children
  }
}
