import axios from 'axios'
import { logger } from 'helpers/logger'
import https from 'https'

// withCredentials is needed for cookies configured globally
axios.defaults.withCredentials = true

const {
  BACKEND_APP_PREFIX: REACT_BACKEND_APP_PREFIX = '/api/v1',
  REACT_APP_UPLOAD_SERVICE_API: UPLOAD_SERVICE_API,
  REACT_APP_EMAIL_API: EMAIL_API,
  REACT_APP_ENVIRONMENT: ENVIRONMENT = 'development',
} = process.env

const BASE_URL = REACT_BACKEND_APP_PREFIX

/**
 * create an axios instance with the given base url
 * maxRetries: number of retries before failing
 * httpsAgent: https agent to use for https requests
 */
const adapter = axios.create({
  baseURL: REACT_BACKEND_APP_PREFIX,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  maxRedirects: 0,
})

adapter.interceptors.request.use((config) => {
  const token = localStorage.getItem('user-token')
  if (token) {
    config.headers.Authorization = `Bearer ${token.replace(/"/g, '')}`
  }
  return config
})

adapter.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // test if request status is not a success status
    logger.info('error', error.response.status)

    const { response } = error

    if (response && (response.status === 400 || response.status === 500)) {
      // publish('api-error', response.data.message || error.message)
      logger.info('error', response.data.message || error.message)
    }

    return Promise.reject(error)
  }
)

export { BASE_URL, EMAIL_API, ENVIRONMENT, UPLOAD_SERVICE_API, adapter }
